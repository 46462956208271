<template>
    <i class="mdi mdi-close-thick email-clear-icon" @mousedown="$emit('clearInput', $event)"></i>
</template>


<style scoped>

.email-clear-icon {
  position: absolute;
  right: 5px;
  padding-right: 0.4rem;
  cursor: pointer;
  color: rgba(240,83,71,0.9);
}
</style>