<script setup>
import ErrorSmall    from "@/components/form/ErrorSmall.vue";
import LabelForInput from "@/components/form/LabelForInput.vue";
import DeleteInputIcon from "@/components/form/DeleteInputIcon.vue";

const valueStreet = defineModel('valueStreet');
const valueStreetNumber = defineModel('valueStreetNumber');

defineProps({
  hintStreet: "",
  hintStreetNumber: "",
  labelStreet: "",
  labelStreetNumber: "",
  nameStreet: "",
  nameStreetNumber: "",
  required: "",
  valueStreet: "",
  valueStreetNumber: "",
  minLengthStreet: 2,
  maxLengthStreet: 20,
  maxLengthStreetNumber: 5,
  errorStreet: "",
  errorStreetNumber: "",
});

const emits = defineEmits(['validateStreet', 'validateStreetNumber']);

const regexWordWithStr = /\b(\w+)([-\s]?)(str\.?|Str\.?)\b\.?/g;

function _normalizeStreetName(input) {
  return input
    .replace(regexWordWithStr, (input, prefix, separator, match) => {
      if (separator === " ") {
        return prefix + " Strasse";
      } else if (separator === "-") {
        return prefix + "-Strasse";
      }
      return prefix + "strasse";
    });
}

</script>

<template>
  <div :class="['form-group', 'form-row', required && 'required']">
    <div class="col-8">
      <LabelForInput :name="nameStreet" :hint="hintStreet" :label="labelStreet" />
      <div>
          <div class="input-container">
            <input
              type="text"
              :class="['form-control','max-width', errorStreet && 'error-border']"
              :id="nameStreet"
              :name="nameStreet"
              v-model="valueStreet"
              :minlength="minLengthStreet"
              :maxlength="maxLengthStreet"
              :required
              @input="valueStreet = $event.target.value"
              @change="$emit('validateStreet', $event); valueStreet = _normalizeStreetName(valueStreet)"
            />
            <DeleteInputIcon v-if="errorStreet" @clearInput="valueStreet = ''" />
          </div>
        </div>
      <ErrorSmall :name="nameStreet" :error="errorStreet" :label="labelStreet"/>
    </div>

    <div class="col-4">
      <LabelForInput :name="nameStreetNumber" :hint="hintStreetNumber" :label="labelStreetNumber" />
      <div>
          <div class="input-container">
            <input
              type="text"
              :class="['form-control','max-width', errorStreetNumber && 'error-border']"
              :id="nameStreetNumber"
              :name="nameStreetNumber"
              v-model="valueStreetNumber"
              :maxlength="maxLengthStreetNumber"
              :required
              @input="valueStreetNumber = $event.target.value"
              @blur="$emit('validateStreetNumber', $event)"
            />
            <DeleteInputIcon v-if="errorStreetNumber" @clearInput="valueStreetNumber = ''" />
          </div>
        </div>
      <ErrorSmall :name="nameStreetNumber" :error="errorStreetNumber" :label="labelStreetNumber" />
    </div>
  </div>
</template>
<style scoped>
.max-width {
  max-width: 500px;
}
</style>
