export default {
  pageTitle: "Bestellung Mitgliedschaft", 
  progressSteps: {
    version: "Version:",
    versionInfoText:
    " Health Info Net AG - ",
  },
  supportPanel: {
    remoteForm: {
      remoteMaintenance: "Fernwartung",
      sessionNumberLabel: "Bitte geben Sie Ihre Session Nummer ein",
      downloadBtn: "Herunterladen",
    },
    customerService: {
      header: "HIN Kundencenter",
      infoMail: " info{'@'}hin.ch",
      supportMail: " support{'@'}hin.ch",
      contactForm: "Kontaktformular",
      contactFormUrl: "https://www.hin.ch/kontakt/kontaktformular/",
      phoneSwiss: " 0848 830 740",
      phoneInternational: " +41 52 235 02 70",
      international: " (International)",
      adminRequest: " (Administrative Anfragen)",
      technicalRequest: " (Technische Anfragen)",
      openingHoursTitle: "Öffnungszeiten: ",
      openingHours: "Montag – Freitag 08.00 – 18.00 Uhr",
      openingHoursNoon: "",
      openingHoursAfternoon: "",
    },
    globalSupport: {
      header: "HIN Mail GLOBAL Support",
      receiverMail: "Für Empfänger verschlüsselster E-Mails",
      globalSupportMail: " global.support{'@'}hin.ch",
      globalSupportPhone: " 0848 22 44 11",
      globalSupportPhoneInternational: " +41 52 235 02 71",
    },
    adress: {
      header: "Adresse",
      companyName: "Health Info Net AG",
      street: "Seidenstrasse 4",
      zipCity: "8304 Wallisellen",
    },
  },
  appErrors: {
    backendCommunicationError:
      "Es gab ein Problem bei der Verbindung mit dem Server." +
      " Bitte versuchen Sie es später erneut." +
      " Falls das Problem weiterhin besteht, wenden Sie sich an den {supportLink}.",
      failedBackendCall:
      "Validierung nicht möglich, bitte überprüfen Sie Ihre Internetverbindung.",
  },
  appComponents: {
    appHeaderTitle: "HIN Mitgliedschaft abschliessen",
    inputTeamName: "Anzeigename",
    btnBack: "Zurück",
    smallText:
      "Als Absender der E-Mail wird dieser Name angezeigt z.B. Praxis Muster",
    inputFirstName: "Vorname",
    inputLastName: "Nachname",
    inputEmail: "E-Mail-Adresse (bestehende)",
    cacheDataText: "Zwischenstand speichern und später fortfahren",
  },
  membersForm: {
    memBodyText:
      "Zur Bestellung Ihrer HIN Mitgliedschaft gehört eine {memTitle}, welche Sie persönlich durchführen müssen. Die Bestellung (inkl. Videoidentifikation) dauert etwa 30 Minuten.",
    memTitle: "Videoidentifikation",
    memExplainText:
      "Erklärungsvideo “Wie funktioniert die Videoidentifikation?”",
    memExplainLink: "https://support.hin.ch/de/videoidentifikation/ablauf-videoidentifikation/",
    memPricingLink: "https://www.hin.ch/de/hin-mitgliedschaft/uebersicht-mitgliedschaft.cfm",
    memFooterTitle: "Verband auswählen",
    firstInfoBoxText: "Für die Mitgliedschaften",
    secondInfoBoxText:
      "gelten spezielle Regelungen, bitte gehen Sie für die Bestellung über folgenden Link:",
    orderLink: "Produktübersicht",
    memListText: {
      header: "Sie benötigen dazu:",
      internetAccess: "Internetzugang – gute Verbindung während des gesamten Vorgangs",
      mobilePhone: "Mobiltelefon oder Tablet / Computer mit Webcam und Mikrofon (Browser: Google Chrome, Mozilla Firefox und Microsoft Edge)",
      validId: "Gültiges Ausweisdokument ",
      mobilePhoneAccess: "Zugang zu einem Mobiltelefon",
      glnNumber: "Ihre GLN (falls Nutzung für EPD gewünscht)",
      validDocs: "Zugelassene Ausweisdokumente:",
      idSwiss: "Personen mit Schweizer Staatsangehörigkeit: Schweizer Identitätskarte oder Schweizer Pass",
      idForeign: "Personen ohne Schweizer Staatsangehörigkeit: gültiges Ausweisdokument des Herkunftslandes mit Ausländerausweis",
    },
    memAssociation: {
      header: "Spezialangebote",
      overviewLink: "Übersicht Mitgliedschaft",
      specialCondition:
        "Wenn Sie Mitglied in einem Verband sind, profitieren Sie gegebenenfalls von Spezialkonditionen (siehe {overviewLink}), beispielsweise in Bezug auf Jahresgebühr und Endung der E-Mail-Adresse {'(Beispiel: @berufsverband-hin.ch)'}.",
    },
    modalTitle: "Bitte beachten",
    modalMemberTitle: "HIN Mitgliedschaft {association}",
    // removed {association} variable here due to text changes
    // code for using the variable is still available in case it is needed
    modalText:
      "Für dieses Angebot gelten spezielle Konditionen. " +
      "Bitte bestellen Sie dieses über folgenden Link: ",
    modalTest:
      "Für dieses Angebot gelten spezielle Konditionen. "
      +
      "Bitte bestellen Sie dieses über folgenden Link: ",
    modalTextUrl: "Angebot bestellen",
    modalDismissButton: "OK",
    selectionNoAssociation: "Kein Partnerverband",
  },
  identityForm: {
    popoverTitle: "Achtung:",
    popoverNoIds: "Bitte wählen Sie mindestens eine eID aus",
    popoverMessage:
      "Um von den {popoverAssocContractUrlText} zu profitieren," +
      " müssen Sie mindestens eine persönliche und eine Team-Identität auswählen. " + 
      "Mit dem Verbandsvorteil erhalten Sie eine E-Mail-Adresse mit der Domäne des Verbandes (z.B. {'@'}physio-hin.ch).",
    popoverMessageFmh: "Um von den Spezialkonditionen für FMH-Mitglieder zu profitieren, müssen Sie mindestens zwei Identitäten auswählen.",
    popoverAssocContractUrlText: "Spezialkonditionen für Mitglieder von Partnerverbänden",
    popoverAssocContractUrl: "https://www.hin.ch/de/hin-mitgliedschaft/kollektivmitgliedschaft/verbaende.cfm", 
    personalCard: {
      persCardHeader: "Meine persönliche eID",
      compareToId:
        "Ist vergleichbar mit einem Ausweisdokument, einfach im digitalen Kontext",
      uniquePerson:
        "Nur eine einzige Person ist berechtigt, die persönliche eID zu nutzen",
      appAccess:
        "Zugriff auf Applikationen mit sensiblen Daten (z.B. EPD, E-Rezept Schweiz Service)",
      hinServices:
        "Nutzung von HIN Services wie HIN Mail, HIN Sign",
      emailExample: "Die E-Mail-Adresse zu einer persönlichen eID lautet zum Beispiel max.mustermann{'@'}hin.ch",
    },
    teamCard: {
      teamCardHeader: "Team-eID",
      multiplePersons: "Kann von mehreren Personen gemeinsam genutzt werden",
      limitedAccessData: "Zugriff auf besonders schützenswerte Daten (z.B. EPD, E-Rezept Schweiz Service) nicht möglich",
      limitedAccessServices: "Eingeschränkter Zugriff auf HIN Services",
      emailExample: "Die E-Mail-Adresse zu einer Team-eID lautet zum Beispiel praxis.mustermann{'@'}hin.ch"
    },
    addButtons: {
      teamLinkText: "Team-eID hinzufügen",
      persLinkText: "Persönliche eID hinzufügen",
      mpaLinkText: "Persönliche eID für MPA / MPK / Fachkraft Apotheke hinzufügen",
    },
    firstPersonalForm: "Persönliche eID (Vertragsnehmer:in/ID-Halter:in)",
    formString: "",
    persFormName: "Persönliche eID (ID-Halter:in)",
    mpaFormString: "Persönliche MPA-eID (ID-Halter:in)",
    trashCanText: "Entfernen",
    whatIsEidPers: "Was ist eine eID?",
    whatIsEidTeam: "Was ist eine eID?",
    whatIsEidLink: "https://support.hin.ch/de/hin-identitaet/unterschied-persoenliche-id-praxis-id/",
    errors: {
      duplicateMailError: "Eine E-Mail-Adresse darf nicht für verschiedene eIDs verwendet werden. " +
        "Die angegebene Adresse muss persönlich sein, da wir diese für die Generierung des Passworts verwenden.",
      hinMailExistingError: "Die E-Mail-Adresse {currentHinEmail} ist bereits einer HIN eID zugeordnet. " +
      "Bitte nehmen Sie Kontakt mit dem {supportLink} auf, um das weitere Vorgehen zu klären.",
    },
  },
  emailForm: {
    forward: "Weiter",
    persHeader: "Persönliche eID",
    mpaHeader: "Persönliche eID für MPA / MPK / Fachperson Apotheke",
    teamHeader: "Team-eID",
    infoTextMail:
      "Bei diesen Adressen handelt es sich um Vorschläge. Die definitiven E-Mail-Adressen werden später verifiziert und vergeben. ",
    infoTextPleaseConsider: "Bitte berücksichtigen Sie bei der Auswahl der E-Mail-Adresse für Ihre persönliche eID folgende Punkte:",
    infoTextFullName: "Die E-Mail-Adresse muss zwingend Ihren vollständigen Nachnamen enthalten",
    infoTextFirstName: "Ihren Vornamen können Sie wahlweise abkürzen oder vollständig angeben",
    infoTextFantasyName: "Fantasienamen sind nicht erlaubt",
    praxisSoftwareLabel:
      "Mitgliedschaft mit folgender Praxissoftware kombinieren",
    praxisSoftwareInfo:
      "Die Kombination mit der Praxissoftware hat Einfluss auf die E-Mail Domäne.",
    praxisSoftwareSelect: "Praxissoftware auswählen",
    noPraxisSoftware: "Keine Praxissoftware verbinden",
    praxisSoftwareOptions: {
      "no-software": "Keine Praxissoftware verbinden",
      softplus: "Softplus",
      physiosys: "Physiosys",
      sophisware: "Sophisware",
    },
  },
  offerForm: {
    errorModal: {
      header: "Es ist ein Fehler aufgetreten",
      text: "Wir konnten Ihr Angebot leider nicht erstellen. Bitte wenden Sie sich an den {supportLink}.",
      errorCode: "Fehlercode",
    },
    offerMismatchModal: {
      header: "Achtung",
      intro: "Die minimale/maximale  Anzahl persönlicher und/oder / minimale/maximale "
        +"Anzahl Team-eID - oder allenfalls nur eIDs für dieses Verbandsangebot - ist nicht erfüllt. "
        +"Bitte bestellen Sie die benötigte Anzahl, ansonsten entfällt der Vorteilsrabatt. "
        +"Zudem erhalten Sie gegebenenfalls keine Mailadresse mit Verbandszusatz. "
        +"Die Konditionen finden Sie auf www.hin.???",
      numTeamIds: "Anzahl Team eIDs",
      numTotPersIds: "Anzahl persönliche eIDs (inkl. MPA)",
      numTotIds: "Anzahl persönliche und Team eIDs zusammen",
      minimum: "Minimum",
      maximum: "Maximum",
    },
    pageHeaders: {
      single: "Einzelmitgliedschaft",
      collective: "Kollektivmitgliedschaft",
      collectiveAssociation: "Kollektivmitgliedschaft Verband",
    },
    tableHeaders: {
      countText: "Anzahl",
      singlePriceText: "Einzelpreis",
      totalPriceText: "Gesamtpreis",
    },
    tableContentAdmission: {
      admissionCostText: "Einmalige Kosten",
      admissionChargeText: "Einmalige Aufnahmegebühr",
      associationBenefitText: "Vorteil Verbandsangebot",
      admissionCostTotalText: "Einmalige Kosten Summe",
      admissionFeeText: "Aufnahmegebühr",
      admissionDiscountText: "Vorteil Verbandsangebot Verband { name }",
    },
    tableContentAnnual: {
      annualCostText: "Jährliche Kosten",
      personalIdsText: "Jahresgebühr Persönliche eID",
      personalIdsDiscount: "Rabatt",
      mpaIdsText: "Jahresgebühr Persönliche eID MPA",
      teamIdsText: "Jahresgebühr Team-eID",
      annualDiscountText: "Vorteil Verbandsangebot Verband { name }",
      annualCostTotalText: "Jährliche Kosten Summe",
    },
    sponsorText: "Sponsor:",
    totalAmountText: "Gesamtbetrag",
    btnForward: "Annehmen und weiter",
    followUp: "Vertragsangaben folgen",
    retired: "Ich bin Pensionär:in",
    currency: "CHF",
    mutationFeeText: "Mutationsgebühr",
  },
  contractDetail: {
    button: {
      back: "Zurück",
      forward: "Vertrag abschliessen",
      savingInProgressTitle: "Speichern..",
      savingInProgressText: "Bitte warten",
      eIdErrorsTitle: "Fehlerhafte Angaben bei Schritt 3",
      eIdErrorsText:
        "Die angegebenen E-Mail-Adressen entsprechen nicht den Anforderungen."
        + " Bitte überprüfen Sie Ihre Angaben in Schritt 3.",
      formErrorsTitle: "Fehlende Angaben",
      formErrorsText: "Füllen Sie bitte alle mit einem Stern (*) gekennzeichneten Felder aus.",
      pharmacyGlnText: "Bitte geben Sie die GLN Ihrer Organisation an (erstes Feld oben).",
      savingErrorsTitle: "Fehler beim Speichern",
      savingErrorsText:
        "Beim Speichern ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre Internetverbindung."
        + " Falls das Problem weiterhin besteht, wenden Sie sich an den { supportLink }.",
      savingTitle: "Daten OK",
      savingText: "Daten werden gespeichert. Sie werden weiter geleitet.",
    },
    title: "Vertrag",
    noBelongToOrg: "Ich gehöre zu keiner Organisation / Institution",
    forOrganization: {
      title: "Organisation",
      titleBusinessContactPerson: "Kontaktperson",
      titleHint: "(Vertragsnehmer:in)",
      orgName: "Name der Organisation",
      email: "E-Mail-Adresse Organisation (bestehend)",
      tel: "Telefon Organisation",
      gln: "GLN Organisation",
      glnLookupPending: "Bitte Geduld, wir schlagen Ihre GLN in RefData nach",
      businessContactPhone: "Mobiltelefon",

      cbAckLegalOrgSingleMembership:
        "Hiermit bestätige ich," +
        " dass die Grundvoraussetzungen zum Abschluss des Vertrages erfüllt sind" +
        " und dass die gemachten Angaben der Wahrheit entsprechen." +
        " Zudem habe ich die folgenden Vertragsunterlagen akzeptiert und gelesen: {cbAckLegalOrgSingleMembershipContract}",
      cbAckLegalOrgSingleMembershipContract: "Rahmenvertrag Einzelmitgliedschaft",
      cbAckLegalOrgSingleMembershipContractLink: "https://www.hin.ch/files/pdf1/hin_rahmenvertrag_einzelmitgliedschaft.pdf",
      cbAckLegalOrgSinglePersonallyResponsible:
        "Ich habe zur Kenntnis genommen," +
        " dass ich mit dem Abschluss des Vertrags als HIN Einzelmitglied" +
        " persönlich datenschutzrechtlich verantwortlich bin - und" +
        " nicht die angegebene Organisation.",

      cbAckLegalOrgCollectiveMembership:
        "Hiermit bestätige ich," +
        " dass die Grundvoraussetzungen zum Abschluss des" +
        " Vertrages erfüllt sind und dass die gemachten Angaben der Wahrheit" +
        " entsprechen." +
        " Zudem habe ich die folgenden Vertragsunterlagen akzeptiert und gelesen: {cbAckLegalOrgCollectiveMembershipContract}",
        cbAckLegalOrgCollectiveMembershipContract: "Rahmenvertrag Kollektiv",
        cbAckLegalOrgCollectiveMembershipContractLink: "https://www.hin.ch/files/pdf1/hin-rahmenvertrag-kollektiv.pdf",
    },
    forPersonal: {
      cbAckLegalPersSingleMembership:
        "Hiermit bestätige ich," +
        " dass die Grundvoraussetzungen zum Abschluss des" +
        " Vertrages erfüllt sind und dass die gemachten Angaben der Wahrheit" +
        " entsprechen." +
        " Zudem habe ich die folgenden Vertragunterlagen akzeptiert und gelesen: {cbAckLegalPersSingleMembershipContract}",
        cbAckLegalPersSingleMembershipContract: "Rahmenvertrag Einzelmitgliedschaft",
        cbAckLegalPersSingleMembershipContractLink: "https://www.hin.ch/files/pdf1/hin_rahmenvertrag_einzelmitgliedschaft.pdf",
      cbAckLegalPersSinglePersonallyResponsible:
        "Ich habe zur Kenntnis genommen," +
        " dass ich mit dem Abschluss des Vertrags als" +
        " HIN Einzelmitglied persönlich datenschutzrechtlich verantwortlich bin.",

      cbAckLegalPersCollectiveMembership:
        "Hiermit bestätige ich," +
        " dass die Grundvoraussetzungen zum Abschluss des" +
        " Vertrages erfüllt sind und dass die gemachten Angaben der Wahrheit" +
        " entsprechen." +
        " Zudem habe ich die folgenden Vertragsunterlagen akzeptiert und gelesen: {cbAckLegalPersCollectiveMembershipContract}",
        cbAckLegalPersCollectiveMembershipContract: "Rahmenvertrag Kollektiv",
        cbAckLegalPersCollectiveMembershipContractLink: "https://www.hin.ch/files/pdf1/hin-rahmenvertrag-kollektiv.pdf",
      cbAckLegalPersCollectivePersonallyResponsible:
        "Ich habe zur Kenntnis genommen," +
        " dass ich ohne Angabe einer Organisation" +
        " (juristischen Person) für den abgeschlossenen Vertrag persönlich" +
        " datenschutzrechtlich verantwortlich bin.",
    },
    inputs: {
      emailCorrespondence: "E-Mail-Adresse für Korrespondenz (bestehend)",
      orderCovercardService: "Ich möchte den {covercardService} aktivieren",
      covercardService: "Covercard Service",
      covercardServiceLink: "https://www.hin.ch/services/hin-covercard-service/",
      covercardTitle: "Covercard",
      covercardItemPrice: "{ amount } CHF",
      cardReaderInfoText: "Liste welcher Kartenleser von Ihrer Praxissoftware unterstützt wird finden Sie {cardReaderHere}.",
      cardReaderlink: "https://www.hin.ch/files/pdf1/praxissoftware-covercard.pdf",
      cardReaderHere: "hier"
    },
    addressAdditional: "Adresszusatz",
    organizationType: "Organisationstyp",
  },
  furtherInformation: {
    glnInfoUrl: "https://www.refdata.ch/de/partner/abfrage/partner-refdatabase-gln",
    glnInfoUrlFr: "",
    button: {
      back: {
        warningTitle: "Zurück zum Vertrag nicht möglich",
        warningText:
          "Die Angaben zum Vertrag können nicht mehr angepasst werden. Bitte schliessen Sie den Bestellvorgang " +
          "(inkl. Videoidentifikation) ab und melden Sie uns allfällige Änderungen per Telefon unter {backPhoneNumber} " +
          "oder {backContactForm}.",
        backPhoneNumber: "0848 830 740",
        backPhoneNumberLink: "tel:0848%20830%20740",
        backContactForm: "Kontaktformular",
      },
      formErrorsTitle: "Fehlende Angaben",
      formErrorsText: "Füllen Sie bitte alle mit einem Stern (*) gekennzeichneten Felder aus.",
      savingErrorsTitle: "Fehler beim Speichern",
      savingErrorsText:
        "Beim Speichern ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre Internetverbindung."
        + " Falls das Problem weiterhin besteht, wenden Sie sich an den  { supportLink } ",
      savingInProgressTitle: "Speichern..",
      savingInProgressText: "Bitte warten",
      savingTitle: "Daten OK",
      savingText: "Daten werden gespeichert. Sie werden weiter geleitet.",
    },
    glnLookupPending:
      "..Bitte einen Moment Geduld, wir suchen Ihre GLN in den Registern",
    person: {
      // removed (Maximum: 3) from the key so the text fits in the button
      addJobName: "Weiteren Gesundheitsberuf hinzufügen",
      title: "Personenangaben",
      header: "Persönliche eID für",
      gln: "GLN Person",
      accessGln: "Für den Zugang zum EPD muss zwingend eine GLN angegeben werden.",
      mpaEpdGlnHint:
        "Für den Zugang zum EPD ist die Angabe einer GLN Pflicht." +
        " Sie können den Bestellprozess abschliessen, ohne eine GLN anzugeben." +
        " Sollten Sie den EPD-Zugang zu einem späteren Zeitpunkt benötigen," +
        " kann die GLN nachträglich angegeben werden.",
      ackLegal:
        "Hiermit bestätige, dass die gemachten Angaben der Wahrheit entsprechen" +
        " und akzeptiere die {privacyPolicy}.",
      privacyPolicy: "Datenschutzerklärung von HIN",
      privacyPolicyLink: "https://www.hin.ch/datenschutzerklaerung/",
      button: {
        forward: "Weiter",
      },
    },
    infoText:
      "Für einen EPD Zugang ist eine GLN zwingend, tragen Sie Ihre GLN unbedingt ein, " +
      "wenn Sie eine haben. ",
    infoTextFindGln: "GLN suchen",
    infoTextFindGlnFr: "",
    cardReaderOrder: "Kostenpflichtiges Lesegerät",
    practiceSoftware: "Welche Praxissoftware setzen Sie ein?",
    tokenExpiredTitle: "Anmeldefrist abgelaufen",
    tokenExpiredText:
      "Ihr Anmeldezeitfenster ist abgelaufen. Bitte melden Sie sich beim {supportLink}.",
    videoIdentAlreadyCompleted:
      "Sie haben die Videoidentifikation bereits abgeschlossen.",
  },
  videoIdentification: {
    loading: "Bitte warten..",
    errorTitle: "Beim Laden der Videoidentifikation ist ein Fehler aufgetreten",
    errorText:
      "Bitte versuchen Sie es erneut. Falls das Problem weiterhin besteht," +
      " wenden Sie sich an den {supportLink}.",
    title: "Videoidentifikation",
    introText:
      "Bei der {title} werden Ihre Angaben " +
      "anhand eines gültigen Ausweisdokuments überprüft.",
    introLink: "Mehr über das Videoidentifikationsverfahren erfahren",
    availability: "Erreichbarkeit",
    businessHours: "Montag bis Samstag von 07.00 bis 22.00 Uhr",
    listHeader:
      "Die Videoidentifkation dauert ca. 10 Minuten. Sie brauchen dazu:",
    button: {
      back: "Zurück",
      forward: "Identifikation starten",
    },
    list: {
      computerOrSmartphone: "Computer oder Smartphone mit Mikrophon und Kamera",
      validIdentityDocument: "Gültiges Ausweisdokument",
      quietBrightPlace: "einen ruhigen und hellen Ort",
    },
    documentInfo: {
      ifUsingSmartphone:
        "Wenn Sie ein Smartphone verwenden:" +
        " Stellen Sie bitte sicher, dass Sie nur mit 4G verbunden sind und kein Wi-Fi aktiviert ist.",
      alsoMakeSure:
      "Bitte stellen Sie ausserdem sicher, dass Ihr Telefon auf dem neuesten Stand ist.",
      withSwissCitizenship:
        "Personen mit Schweizer Staatsangehörigkeit:" +
        " Schweizer Identitätskarte oder Schweizer Pass",
      withoutSwissCitizenship:
        "Personen ohne Schweizer Staatsangehörigkeit:" +
        " gültiges Ausweisdokument des Herkunftslandes mit Ausländerausweis",
    },
  },
  identSuccess: {
    withEids: {
      title:
        "Einladung für persönliche Identität | Einladungen für persönliche Identitäten",
      intro:
        "Sie haben eine persönliche Identität (eID) für eine weitere Person bestellt." +
        " Informieren Sie diese Person über die weiteren Schritte" +
        " und senden Sie eine Einladung zur Videoidentifikation." +
        " | Sie haben mehrere persönliche Identitäten (eIDs) für weitere Personen bestellt." +
        " Informieren Sie diese Personen über die weiteren Schritte und senden Sie" +
        " eine Einladung zur Videoidentifikation.",
      tableExplanation:
        "Einladung per E-Mail versenden | Einladungen per E-Mail versenden",
      infoByEmail:
      "Nach Bearbeitung Ihrer eID werden Ihnen die Zugangsdaten per E-Mail zugestellt."
        // "Informationen zum weiteren Vorgehen erhalten Sie" +
        // " nach dem Versenden der Einladung per E-Mail an { email }." +
        // " | Informationen zum weiteren Vorgehen erhalten Sie" +
        // " nach dem Versenden der Einladungen per E-Mail an { email }.",
    },
    withoutEids: {
      title: "Videoidentifikation erfolgreich",
      text:
        "Sie haben die Videoidentifikation erfolgreich durchgeführt." +
        " Informationen zum weiteren Vorgehen haben wir Ihnen per Mail zugesendet.",
    },
    error: {
      title: "Bei der Übertragung der Daten ist ein Fehler aufgetreten",
      restart: "Bitte starten Sie die Videoidentifikation neu:",
      text:
        "Sie können die Identifikation auch zu einem späteren Zeitpunkt durchführen." +
        " Den entsprechenden Link haben wir Ihnen an {email}" +
        " gesendet.",
      restartIdent: "Hier",
      noMatch:
        "Ihre Daten konnten nicht gefunden werden. Bitte wenden Sie sich" +
        " an den {supportLink}.",
      noMatchButShowSuccess:
        "Sie haben die Videoidentifikation erfolgreich durchgeführt.",
      supportLink: "https://support.hin.ch/de/",
      support: "HIN Support",
    },
    loading: {
      title: "Bitte warten",
      text: "Bitte haben Sie einen Moment Geduld, Sie werden weitergeleitet.",
    },
    table: {
      description:
        "Tabelle mit Auflistung der eIDs, welche ein Einladungsmail erhalten",
      header: {
        name: "ID Halter/-in",
        email: "E-Mail-Adresse",
        subject: "Betreff der E-Mail",
      },
      body: {
        subject: "Aktivierung Ihrer persönlichen eID",
      },
    },
    button: {
      sendInvitationMails:
        "E-Mail versenden und Fenster schliessen" +
        " | E-Mails versenden und Fenster schliessen",
      invitationMailsSent:
        "E-Mail versandt. Sie können das Fenster jetzt schliessen" +
        " | E-Mails versandt. Sie können das Fenster jetzt schliessen",
      invitationMailsFailure:
        "Fehler beim Senden. Bitte kontaktieren Sie den {supportLink}.",
      close: "Sie können das Fenster jetzt schliessen",
    },
  },
  // Generic keys for forms
  form: {
    button: {
      forward: "Weiter",
    },
    association: "Berufsverband",
    associationMemberNumber: "Mitgliedernummer Berufsverband",
    birthdate: "Geburtsdatum",
    correspondenceLanguage: "Korrespondenzsprache",
    errorMessage: "Die rot markierten Felder sind leer oder beinhalten fehlerhafte Angaben.",
    street: "Strasse",
    streetNo: "Nr.",
    city: "Ort",
    zipCode: "PLZ",
    selectionPlease: "(Bitte wählen)",
    country: "Land",
    glnErrors: {
      onlyDigits: "Die GLN darf nur Ziffern enthalten.",
      noMatch: "Die eingegebene GLN wurde im Register nicht gefunden.",
      noMatchPerson:
        "Kein Eintrag gefunden. Geben Sie eine gültige GLN an oder lassen Sie das Feld leer.",
      tooShort: "Die angegebene GLN ist zu kurz.",
      tooLong: "Die angegebene GLN ist zu lang.",
      unknownError:
        "Die angegebene GLN konnte nicht gefunden werden.",
    },
    glnText:
      "Die Global Location Number (GLN) dient der eindeutigen Identifikation" +
      " natürlicher und juristischer Personen (z.B. Gesundheitsfachperson, -organisation)" +
      " und physischer Lokationen (z.B. Gebäude, Lagerraum). {0}",
    glnTitle: "GLN",
    glnUrlText: "Weitere Informationen",
    profession: "Beruf / Gesundheitsberuf",
    profTitle: "Fachtitel",
    salutation: "Anrede",
    salTitle: "Akademischer Titel",
    mobilePhone: "Mobiltelefon",
    name: "Name",
    firstName: "Vorname",
    lastName: "Nachname",
    billingAddress: "Rechnungsadresse",
    differentInvoiceAddress: "Abweichende Rechnungsadresse",
    feedbackMessageTitle: "Bemerkung",
    feedbackMessageLabel: "Möchten Sie uns etwas mitteilen?",
    contractTerms: "Vertragsbestimmungen",
    errors: {
      "Failed string anyNv missing parameter value":
        "Bitte treffen Sie eine Auswahl.",
      "Missing property": "Bitte ausfüllen",
      codeLabel: "Error-Code: ",
      specialCharacters: "Bitte verwenden Sie keine Sonderzeichen oder Zahlen",
      missingProperty: "Bitte {label} eintragen",
      unknownMailServer: "Die E-Mail-Domäne (z.B. {'@'}hin.ch) kann nicht verändert werden.",
      invalidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
      checkRegulations: "Bitte akzeptieren Sie die Vertragsbestimmungen",
      invalidTelNrSwitzerland: "Bitte Telefonnummer mit 9 Ziffern eintragen",
      invalidTelNrLiechtenstein: "Bitte Telefonnummer mit 7 Ziffern eintragen",
      invalidTelNrOther: "Bitte Telefonnummer mit 7-13 Ziffern eintragen",
    },
  },
  Fernwartung:
    "TODO: make key English, Fill in Text. I have just put the key here to silence a warning",
  countries: {
    CH: "Schweiz",
  },
  phoneCountryCodes: {
    CH: "CH +41",
  },
  register: {
    infoFoundInRegister: "Eintrag in {register} gefunden",
  },
  sidebar: {
    steps: {
      membership: "Hinweise zur Bestellung",
      identities: "Identitäten (eIDs)",
      identitiesAndMail: "Identitäten (eIDs) und E-Mail-Adressen",
      hinEmails: "E-Mail-Adressen",
      hinEmailSelect: "E-Mail-Adressen wählen",
      offer: "Angebot",
      contractDetail: "Vertragsangaben",
      furtherInfo: "Personenangaben",
      videoIdentification: "Videoidentifikation",
    },
  },
};
