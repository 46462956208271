<script setup>
import { ref } from 'vue';

import Autocomplete        from "@/components/form/Autocomplete.vue";
import CompanySearch       from "@/components/contract-detail/CompanySearch.vue";
import FormTitle           from "@/components/form/FormTitle.vue";
// import InputCheckbox       from "@/components/form/InputCheckbox.vue";
import InputCity           from "@/components/form/InputCity.vue";
import DatePicker          from "@/components/form/DatePicker.vue";
import InputEmail          from "@/components/form/InputEmail.vue";
import InputStreet         from "@/components/form/InputStreet.vue";
import InputTel            from "@/components/form/InputTel.vue";
import InputText           from "@/components/form/InputText.vue";
import InputTextName       from "@/components/form/InputTextName.vue";
import InputTextPopoverGln from "@/components/form/InputTextPopoverGln.vue";

import { useAgwManagerService } from "@/services/AgwManagerService";
import {setGlnError} from "@/utils/gln.js";

const props = defineProps({
  errors: Object,
  info: Object,
  inputs: Object,
  inputOptions: Object,
  language: "",
  validators: Object,
  hasPersEids: {type: Boolean},
});

const agwManagerService = useAgwManagerService();

// Look up GLN and if there is a match,
// prefill some fields by setting store values (via inputs).
function checkGln(event) {
  const gln = event.target.value;
  props.info.inputOrgGln = null;
  setGlnError(gln, props.errors, "inputOrgGln");
  // Immediate check, without waiting for "blur"
  if (event.target.value?.length === 13 && !props.errors.inputOrgGln) {
    lookupGln(event);
    return;
  }
  _clearInfo();
}
async function lookupGln(event) {
  props.info.inputOrgGln = null;
  const gln = event.target.value;
  // Entering GLN is error prone. Try to be helpful.
  setGlnError(gln, props.errors, "inputOrgGln");
  if (props.errors.inputOrgGln) return;

  props.info.inputOrgGln = ["contractDetail.forOrganization.glnLookupPending"];

  const data = await agwManagerService.fetchJson(
    `/productfinder/gln/${gln}`,
    {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      },
    }
  );
  if (data?.result) {
    // If we get a response we know GLN was found in RefData.
    props.info.inputOrgGln = [
      "register.infoFoundInRegister",
      { register: "RefData" }
    ];

    props.inputs.inputOrgName = data.result.description1;
    // Pick address from first "role". That seems to be
    // the "main entry".
    const first = data.result.roles[0];
    if (first) {
      props.inputs.inputOrgStreet        = first.street;
      props.inputs.inputOrgStreetNumber  = first.streetNumber;
      props.inputs.inputOrgAddressSuffix = first.postbox;
      props.inputs.inputOrgZipCode       = first.zip;
      props.inputs.inputOrgCity          = first.city;
      props.inputs.inputOrgCountry       = first.country;

      // Some GLN do not have street or other field set.
      // In that case, try to find information via company name.
      // Look up company name, take first entry and use its UID.
      delete first.canton; // We do not need the canton
      if (Object.values(first).some(value => value === null)) {
        const data = await fetchDataFromServer(props.inputs.inputOrgName);
        if (data?.result?.companies.length > 0) {
          getCompanyAddress(data.result.companies[0].uid);
        }
      }

      _reValidate();
    }
    props.errors.inputOrgGln = '';
    return;
  }
  props.info.inputOrgGln = null;
  if (data?.errors[0].message === 'No match'){
    props.errors.inputOrgGln = ['form.glnErrors.noMatch'];
  }
  else {
    props.errors.inputOrgGln = ['form.glnErrors.unknownError'];
  }
}

// Look up company name for VAT number

let timerId;
let controller = new AbortController();
async function lookupCompanyName(event) {
  orgNameSuggestions.value = [];
  props.validators.required(event);
  const companyName = event.target.value;
  if (! companyName || companyName.length < 2) {
    return;
  }

  controller.abort();
  controller = new AbortController();
  clearTimeout(timerId);
  timerId = setTimeout(async () => {
    const data = await fetchDataFromServer(companyName);
    if (data?.result?.companies.length > 0) {
      orgNameSuggestions.value = data.result.companies;

      // If, by typing, exactly one company results,
      // immediately preset fields. I am turning that off for the moment.
      // User should select in CompanySearch.

      // Searching even for an exact company name like "Nestlé AG"
      // can yield multiple entries(!).
      // The behavior is the same as on zefix.ch
      // However, as far as I can see, all entries have the same UID.
      // Build a lookup object in the form {123: 1, 999: 1, ...}
      // and if there is only one key, make the detailed call.
      // const uids = data.result.companies.reduce((acc, obj) => {
      //   acc[obj.uid] = 1;
      //   return acc;
      // }, {});
      // if (Object.keys(uids).length === 1) {
      //   getCompanyAddress(data.result.companies[0].uid);
      // }
    }
  }, 1000); // 1000ms = 1 second
}

let orgNameSuggestions = ref([]);
async function fetchDataFromServer(companyName) {
  const { signal } = controller;
  return await agwManagerService.fetchJson(
    `/productfinder/company/search?name=${companyName}`,
    {
      signal,
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      },
    }
  );
}

async function getCompanyAddress(uid) {
  const detail = await agwManagerService.fetchJson(
    `/productfinder/company/${uid}`,
    {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      },
    }
  );
  if (detail?.result?.length > 0) {
    // Again, we may get multiple entries.
    // Pick first.
    const first = detail.result[0];
    props.inputs.inputOrgStreet        = first.street;
    props.inputs.inputOrgStreetNumber  = first.streetNumber;
    props.inputs.inputOrgAddressSuffix = first.postbox;
    props.inputs.inputOrgZipCode       = first.zip;
    props.inputs.inputOrgCity          = first.city;

    // save UID to store
    props.inputs.inputOrgUid = uid;
    _reValidate();
  }
}

async function handleEmailOk(event, property) {
  props.inputs[property] =
    await props.validators.backend(event, 'contractDetailOrg');
}

function _clearInfo() {
  props.info.inputOrgGln = null;
}

function _reValidate() {
  for (const n of ['Name', 'Street', 'StreetNumber', 'ZipCode', 'City', 'Country']) {
    const inputName = `inputOrg${n}`;
    props.validators.required({target: {name: inputName, value: props.inputs[inputName]}});
  }
}
</script>

<template>
  <div data-test="for-organization">
    <FormTitle
      class="mt-2"
      :title="$t('contractDetail.forOrganization.title')"
    />
    <InputTextPopoverGln
      name="inputOrgGln"
      :label="$t('contractDetail.forOrganization.gln')"
      :info="info.inputOrgGln"
      minLength=0
      maxLength=13
      v-model="inputs.inputOrgGln"
      :error="errors.inputOrgGln"
      @input="(event) => checkGln(event)"
      @clear="errors.inputOrgGln = ''"
    />
    <CompanySearch
      :suggestions="orgNameSuggestions"
      name="inputOrgName"
      :label="$t('contractDetail.forOrganization.orgName')"
      required="required"
      v-model="inputs.inputOrgName"
      :error="errors.inputOrgName"
      @input="(event) => lookupCompanyName(event)"
      @validate="(event) => validators.required(event)"
      @done="(result) => getCompanyAddress(result)"
    />
    <InputStreet
      nameStreet="inputOrgStreet"
      nameStreetNumber="inputOrgStreetNumber"
      :labelStreet="$t('form.street')"
      :labelStreetNumber="$t('form.streetNo')"
      required="required"
      v-model:valueStreet="inputs.inputOrgStreet"
      v-model:valueStreetNumber="inputs.inputOrgStreetNumber"
      :errorStreet="errors.inputOrgStreet"
      :errorStreetNumber="errors.inputOrgStreetNumber"
      @validateStreet="(event) => validators.required(event)"
      @validateStreetNumber="(event) => validators.required(event)"
    />
    <InputText
      name="inputOrgAddressSuffix"
      :label="$t('contractDetail.addressAdditional')"
      v-model="inputs.inputOrgAddressSuffix"
      :error="errors.inputOrgAddressSuffix"
    />
    <InputCity
      nameCity="inputOrgCity"
      nameZipCode="inputOrgZipCode"
      :labelCity="$t('form.city')"
      :labelZipCode="$t('form.zipCode')"
      required="required"
      v-model:valueCity="inputs.inputOrgCity"
      v-model:valueZipCode="inputs.inputOrgZipCode"
      :errorCity="errors.inputOrgCity"
      :errorZipCode="errors.inputOrgZipCode"
      @validateCity="(event) => validators.required(event)"
      @validateZipCode="(event) => validators.required(event)"
    />
    <Autocomplete
      name="inputOrgCountry"
      :label="$t('form.country')"
      :language
      required="required"
      :suggestions="inputOptions.countries"
      v-model="inputs.inputOrgCountry"
      :error="errors.inputOrgCountry"
      @validate="(event) => validators.autocomplete(event, inputOptions.countries)"
    />
    <Autocomplete
      :showPleaseSelect="true"
      name="inputOrgType"
      :label="$t('contractDetail.organizationType')"
      :language
      required="required"
      :suggestions="inputOptions.orgType"
      v-model="inputs.inputOrgType"
      :error="errors.inputOrgType"
      @validate="(event) => validators.autocomplete(event, inputOptions.orgType)"
    />
    <InputEmail
      name="inputOrgEmail"
      :label="$t('contractDetail.forOrganization.email')"
      required="required"
      v-model="inputs.inputOrgEmail"
      :error="errors.inputOrgEmail"
      @validate="(event) => handleEmailOk(event, 'orgEmailOk')"
    />
    <InputTel
      namePhoneCountry="inputOrgPhoneCountry"
      namePhone="inputOrgPhone"
      :label="$t('contractDetail.forOrganization.tel')"
      :language
      :optionsCountry="inputOptions.telCountries"
      topic="countries"
      required="required"
      v-model:selectedPhoneCountry="inputs.inputOrgPhoneCountry"
      v-model:valuePhone="inputs.inputOrgPhone"
      :errorPhoneCountry="errors.inputOrgPhoneCountry"
      :errorPhone="errors.inputOrgPhone"
      @validatePhoneCountry="(event, options) => validators.autocomplete(
        event, inputOptions.countries)"
      @validatePhone="(event) => validators.phone(event, inputs.inputOrgPhoneCountry)"
    />
    <Autocomplete
      name="inputOrgLanguage"
      :label="$t('form.correspondenceLanguage')"
      :language
      required="required"
      :suggestions="inputOptions.languages"
      v-model="inputs.inputOrgLanguage"
      :error="errors.inputOrgLanguage"
      @validate="(event) => validators.autocomplete(event, inputOptions.languages)"
    />
    <FormTitle
      :title ="$t('contractDetail.forOrganization.titleBusinessContactPerson')"
    />
    <Autocomplete
      :showPleaseSelect="true"
      name="inputBusinessContactSalutation"
      :label="$t('form.salutation')"
      :language
      required="required"
      :suggestions="inputOptions.salutations"
      v-model="inputs.inputBusinessContactSalutation"
      :error="errors.inputBusinessContactSalutation"
      @validate="(event) => validators.autocomplete(event, inputOptions.salutations)"
    />
    <InputTextName
      nameFirst="inputBusinessContactFirstname"
      nameLast="inputBusinessContactLastname"
      :labelFirst="$t('form.firstName')"
      :labelLast="$t('form.lastName')"
      requiredFirst="required"
      requiredLast="required"
      v-model:valueFirst="inputs.inputBusinessContactFirstname"
      v-model:valueLast="inputs.inputBusinessContactLastname"
      :errorFirst="errors.inputBusinessContactFirstname"
      :errorLast="errors.inputBusinessContactLastname"
      @validateFirst="(event) => validators.required(event)"
      @validateLast="(event) => validators.required(event)"

    />
    <InputEmail
      name="inputBusinessContactEmail"
      :label="$t('contractDetail.inputs.emailCorrespondence')"
      required="required"
      v-model="inputs.inputBusinessContactEmail"
      :error="errors.inputBusinessContactEmail"
      @validate="(event) => handleEmailOk(event, 'businessContactEmailOk')"
    />
    <InputTel
      namePhoneCountry="inputBusinessContactPhoneCountry"
      namePhone="inputBusinessContactPhone"
      :label="$t('contractDetail.forOrganization.businessContactPhone')"
      :language
      :optionsCountry="inputOptions.telCountries"
      topic="countries"
      required="required"
      v-model:selectedPhoneCountry="inputs.inputBusinessContactPhoneCountry"
      v-model:valuePhone="inputs.inputBusinessContactPhone"
      :errorPhoneCountry="errors.inputBusinessContactPhoneCountry"
      :errorPhone="errors.inputBusinessContactPhone"
      @validatePhoneCountry="(event, options) => validators.autocomplete(
        event, inputOptions.countries)"
      @validatePhone="(event) => validators.phone(event, inputs.inputBusinessContactPhoneCountry)"
    />
    <DatePicker v-show="! hasPersEids"
      name="inputBusinessContactBirthdate"
      :label="$t('form.birthdate')"
      required="required"
      v-model="inputs.inputBusinessContactBirthdate"
      :error="errors.inputBusinessContactBirthdate"
      :language="language"
      @validate="(event) => validators.required(event)"
    />

    <FormTitle
      :title ="$t('form.billingAddress')"
    />
    <!-- <InputCheckbox
      v-model="inputs.cbDifferentInvoiceAddress"
      name="cbInvoiceAddressForOrganization"
      :label="$t('form.differentInvoiceAddress')"
    />

    <div v-show="inputs.cbDifferentInvoiceAddress">
      <InputText
        name="forOrganization.invoiceAddress.inputLastname"
        :label="$t('form.name')"
        required="required"
        v-model="inputs.invoiceAddress.inputLastname"
        :error="errors.invoiceAddress.inputLastname"
        @validate="(event) => validators.required(event)"
      />
      <InputStreet
        nameStreet="forOrg.invoiceAddress.inputStreet"
        nameStreetNumber="forOrg.invoiceAddress.inputStreetNumber"
        :labelStreet="$t('form.street')"
        :labelStreetNumber="$t('form.streetNo')"
        required="required"
        v-model:valueStreet="inputs.invoiceAddress.inputStreet"
        v-model:valueStreetNumber="inputs.invoiceAddress.inputStreetNumber"
        :errorStreet="errors.invoiceAddress.inputStreet"
        :errorStreetNumber="errors.invoiceAddress.inputStreetNumber"
        @validateStreet="(event) => validators.required(event)"
        @validateStreetNumber="(event) => validators.required(event)"
      />
      <InputText
        name="forOrg.invoiceAddress.inputSuffix"
        :label="$t('contractDetail.addressAdditional')"
        v-model="inputs.invoiceAddress.inputSuffix"
      />
      <InputCity
        nameCity="forOrg.invoiceAddress.inputCity"
        nameZipCode="forOrg.invoiceAddress.inputZipCode"
        :labelCity="$t('form.city')"
        :labelZipCode="$t('form.zipCode')"
        required="required"
        v-model:valueCity="inputs.invoiceAddress.inputCity"
        v-model:valueZipCode="inputs.invoiceAddress.inputZipCode"
        :errorCity="errors.invoiceAddress.inputCity"
        :errorZipCode="errors.invoiceAddress.inputZipCode"
        @validateCity="(event) => validators.required(event)"
        @validateZipCode="(event) => validators.required(event)"
      />
      <Autocomplete
        name="forOrg.invoiceAddress.inputCountry"
        :label="$t('form.country')"
        :language
        required="required"
        :suggestions="inputOptions.countries"
        v-model="inputs.invoiceAddress.inputCountry"
        :error="errors.invoiceAddress.inputCountry"
        @validate="(event) => validators.autocomplete(event, inputOptions.countries)"
      />
    </div> -->

    <div v-show="!inputs.cbDifferentInvoiceAddress">
      <p>{{ inputs.inputOrgName }}</p>
      <p>
        {{ inputs.inputBusinessContactFirstname }} {{ inputs.inputBusinessContactLastname }}
      </p>
      <p>
        {{ inputs.inputOrgStreet }} {{ inputs.inputOrgStreetNumber }}
      </p>
      <p>{{ inputs.inputOrgAddressSuffix }}</p>
      <p>
        {{ inputs.inputOrgZip }} {{ inputs.inputOrgCity }}
      </p>
      <p>{{ inputs.inputOrgCountry
        ? inputOptions.countries[language]?.filter(
            item => item.key === inputs.inputOrgCountry)[0]?.[language]
        : "" }}</p>
    </div>
  </div>
</template>


