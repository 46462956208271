<template>
  <div>
    <TeamForm
      data-test="teamForm"
      v-for="(form, index) in teamForms"
      :key="index"
      :ref="(el) => cardRefs[index] = el"
      :teamName="teamForms[index].teamName"
      :form="form"
      :formString="teamFormString"
      :trashCanText="trashCanText"
      :index="index"
      :validators="validators"
      :formName="formName"
      @updateValue="handleChange(form, $event)"
        @removeForm="removeForm($event)"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import TeamForm from "./TeamForm.vue";
import { useIdentityStore } from "../store/IdentityStore";

const { teamForms } = useIdentityStore();

const props = defineProps({
  teamFormString: String,
  trashCanText: String,
  validators: Object,
  formName: String,
});

const identityStore = useIdentityStore();

const removeForm = (index, formName) => {
  const payload = {
    index: props.index,
    formName: props.formName,
  };
  identityStore.removeForm(payload);
};

const cardRefs = ref([]);

function focusCard(index) {
  cardRefs.value[index].focusFirstInput();
}

defineExpose({
  focusCard
});

function handleChange(form, event) {
  form.hinEmail.value = event.target.value;
}
</script>
