<template>
  <div id="d--wrapper" v-if="!inIframe">
    <app-header />
    <div class="col-12">
      <support-panel />
      <progress-steps
      v-if="!appStore.isInvitedUser"
        class="col-lg-3 col-md-1 col-sm-1 col-1"
        :active="appStore.activeStep"
        :steps="[
          {
            key: 1,
            label: 'sidebar.steps.membership',
          },
          {
            key: 2,
            label: 'sidebar.steps.identities',
          },
          {
            key: 3,
            label: 'sidebar.steps.hinEmails',
          },
          {
            key: 4,
            label: 'sidebar.steps.offer',
          },
          {
            key: 5,
            label: 'sidebar.steps.contractDetail',
          },
          {
            key: 6,
            label: 'sidebar.steps.furtherInfo',
          },
          {
            key: 7,
            label: 'sidebar.steps.videoIdentification',
          },
        ]"
      />
      <progress-steps
        v-if="appStore.isInvitedUser"
        class="col-lg-3 col-md-1 col-sm-1 col-1"
        :active="appStore.activeStepInvite"
        :steps="[
          {
          key: 1,
          label: 'sidebar.steps.furtherInfo',
          },
          {
          key: 2,
          label: 'sidebar.steps.videoIdentification',
          },
        ]"
      />
    </div>
    <div class="col-lg-9 col-md-11 col-sm-11 col-11">
      <div class="row d--spacing-vt-m">
        <div class="col">
          <basic-form :header="appStore.header" :headerNum="appStore.headerNum">
            <template v-slot:mask>
              <router-view />
            </template>
          </basic-form>
        </div>
      </div>
    </div>
  </div>

  <!-- in iframe. No navigation / sidebar etc.
       2024-11-09 currently not used, see below, in "inIframe".
  -->
  <div v-else>
    <div class="col-12 col-md-9">
      <div class="row d--spacing-vt-m">
        <div class="col">
          <basic-form :header="appStore.header" :showCaching="false">
            <template v-slot:mask>
              <router-view />
            </template>
          </basic-form>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-9 col-md-11 col-sm-11 col-11">
      <p class="version-tag text-right">
        <small
        > &copy; {{ currentYear }}{{ $t("progressSteps.versionInfoText") }}
          {{ $t("progressSteps.version") }} {{ version }}</small
        >
      </p>
    </div>
  </div>
  <!-- Modal window only shown if communication with backend failed -->
  <Modal
      v-show="modalErrorOpen"
      :header="$t('membersForm.modalTitle')"
      :bodyComponent="ErrorModalBody"
      :bodyComponentProps="{ showBackendError: true }"
      @close="modalErrorOpen=false"
    />
</template>

<script setup>
import { ref, getCurrentInstance, watch, onMounted, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAppStore } from "@/store/AppStore";
import { useInputOptionStore } from "@/store/InputOptionStore";
import { useVideoIdentStore } from "@/store/VideoIdentStore"

import AppHeader from "@/components/AppHeader.vue";
import SupportPanel from "@/components/sidebar/SupportPanel.vue";
import ProgressSteps from "@/components/sidebar/ProgressSteps.vue";
import BasicForm from "./components/BasicForm.vue";
import Modal from "@/components/Modal.vue";
import ErrorModalBody from "./components/offer/ErrorModalBody.vue";

const { appContext } = getCurrentInstance();
const version = appContext.config.globalProperties.$version;
const currentYear = new Date().getFullYear();

const router = useRoute();
const appStore = useAppStore();
const videoIdentStore = useVideoIdentStore();
const inviteRouter = useRouter();
appStore.changeHeader(router);

const inputOptionStore = useInputOptionStore();
inputOptionStore.fillInputOptions();
let modalErrorOpen = ref(inputOptionStore.modalErrorOpen);

onBeforeMount(() => {
  const cookie = window.document.cookie;
  console.log("cookie: ", cookie);
  appStore.getCsrfTokenFromCookie(cookie);
});

watch(() => inputOptionStore.modalErrorOpen,
  (newValue) => { modalErrorOpen.value = newValue }
)
onMounted(() => {
  appStore.handleIdentInvitation();
  if (appStore.isInvitedUser) {
    inviteRouter.push("/onboarding?invitation="+videoIdentStore.token);
    appStore.activeStepInvite = 1;
  }
})

const inIframe =
  // 2024-11-09 IDnow always navigating us on success / failure.
  // They claim to open a new window, but that does not happen.
  // Instead, it looks as if our top frame is navigated.
  // With that behaviour, always show navigation.
  // Should it change, re-activate here.
  // window.location.pathname === "/ident-success" ||
  // window.location.pathname === "/ident-failure"
  //   ? ref(true)
  //   : ref(false);
  ref(false);
</script>

<style>
.custom-select:focus {
  border-color: #f29800;
}
.form-control:focus {
  border-color: #f29800;
}
.input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.error-border {
  border-color: #F05347;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

body {
  font-family: 'Lato', sans-serif;
}

.lato-font {
  font-family: 'Lato', sans-serif;
}

.version-tag {
  position: relative;
  font-size: 12px;
  color: #6c757d;
  padding-right: 10px;
}
</style>
