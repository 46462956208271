import { defineStore } from "pinia";
import { useAgwManagerService } from "@/services/AgwManagerService";
import { useAppStore }          from "@/store/AppStore";
import { useMembersStore }      from "@/store/MembersStore";
import { useIdentityStore }     from "@/store/IdentityStore";
import { useInputOptionStore }  from "@/store/InputOptionStore";

const agwManagerService = useAgwManagerService();

export const useOfferStore = defineStore("OfferStore", {
  state: () => {
    return {
      offer: {},
      errors: {},
      pageHeader: "",
      errorModalOpen: false,
      offerMismatchModalOpen: false,
      isLoading: false,
      retiredCheckbox: false,
    };
  },
  getters: {
    associationName: (state) => {
      const appStore = useAppStore();
      const inputOptionStore = useInputOptionStore();
      // We must only report an association offer if the offered
      // contractSet looks like requirement of association were met.
      // (Minimum numbers of eIDs, usually.)
      if (/KOLLEKTIV_/.test(state.offer.meta?.offeredSet.name)) {
        if (inputOptionStore.inputOptions.associations?.de) {
            const filtered = inputOptionStore.inputOptions.associations.de.filter(
                item => item.contractSet === state.offer.meta.offeredSet.name
            );
            const lang = appStore.currentLanguage.toLowerCase();
            const first = filtered[0];
            if (! first) {
              return "";
            }
            return first[lang];
        }
        return inputOptionStore.inputOptions.associations?.de?.filter(
          item => item.contractSet === state.offer.meta.offeredSet.name)[0][appStore.currentLanguage.toLowerCase()];

      }
      return "";

      // Original code, seemingly making table vanish in prod mode.(?!)
      // return /KOLLEKTIV_/.test(state.offer.meta?.offeredSet.name)
      //   ? inputOptionStore.inputOptions.associations?.de?.filter(
      //       item => item.contractSet === state.offer.meta.offeredSet.name)
      //         [0][appStore.currentLanguage.toLowerCase()]
      //   : "";
    },
      // outcommented the code because the checkbox is used on screen 1 now 
      // that means that we don't have any eId's yet
    showRetiredCheckbox: (state) => {
      // const identityStore = useIdentityStore();
      const membersStore = useMembersStore();
      return membersStore.associationSelection === 'FMH'
        // && identityStore.mpaForms.length === 0
        // && identityStore.personalForms.length === 1
        // && identityStore.teamForms.length === 0
    },
  },
  actions: {
    async getOffer() {
      const identityStore = useIdentityStore();
      const membersStore = useMembersStore();
      const params = new URLSearchParams();
      const defaultAssociationKey = membersStore.defaultAssociation.key;
      if (membersStore.associationSelection &&
        membersStore.associationSelection !== defaultAssociationKey) {
        params.append("association", membersStore.associationSelection);
      }
      if (this.showRetiredCheckbox) {
        params.append("retired", this.retiredCheckbox);
      }
      for (const type of ['personal', 'mpa', 'team']) {
        params.append(type+"Ids", identityStore[type+"Forms"].length);
      }
      const loaderTimeout = setTimeout(() => {
        this.isLoading = true;
      }, 1000);
      const responseData = await agwManagerService.fetchJson(
        "/productfinder/offer?"+params.toString(),
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (responseData.errors) {
        this.errors = responseData.errors;
        this.errorModalOpen = true;
        return;
      }
      clearTimeout(loaderTimeout);
      this.isLoading = false;
      this.offer = responseData.result;
      this.pageHeader = "offerForm.pageHeaders."
      +_pageHeader(responseData.result.meta.offeredSet.name);

      if (responseData.result.meta?.failedSet?.name) {
        this.offerMismatchModalOpen = true;
      }
    }
  },
  persist: {
    storage: sessionStorage,
  }
});

function _pageHeader(contractSetIdentifier) {
  if (contractSetIdentifier.startsWith('EINZEL')) {
    return "single";
  }
  if (contractSetIdentifier === 'KOLLEKTIV') {
    return 'collective';
  }
  return 'collectiveAssociation';
}
