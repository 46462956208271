import { useAgwManagerService } from "@/services/AgwManagerService";
import { defineStore } from "pinia";
import { remove as removeDiacritics } from "diacritics";

const agwManagerService = useAgwManagerService();

export const useIdentityStore = defineStore("IdentityStore", {
  state: () => {
    return {
      maxForms: 50,
      personalForms: [],
      mpaForms: [],
      teamForms: [],
      formString: "",
      timer: 1000 * 60 * 60 * 24, // 24 hours
      hasEmpty: false,
      hasErrors: false,
      hinDomain: "@hin.ch",
    };
  },
  getters: {
    hasPersEids: (state) => {
      return state.personalForms.length > 0
          || state.mpaForms.length      > 0
    },
    hasValues: (state) => {
      const hasFilledPersForms = state.personalForms.some(item =>
        item.firstName.value && item.lastName.value && item.email.value
      );
      const hasFilledMpaForms = state.mpaForms.some(item =>
        item.firstName.value && item.lastName.value && item.email.value
      );
      const hasFilledTeamForms = state.teamForms.length > 0 && state.teamForms.some(item => item.teamName.value);

      return hasFilledPersForms || hasFilledMpaForms || hasFilledTeamForms;
    },
    getIdCount(state) {
      return state.personalForms.length + state.mpaForms.length + state.teamForms.length;
    }
  },
  actions: {
    checkForEmpty() {
      this.deleteEmptyIds(this.teamForms);
      this.deleteEmptyIds(this.mpaForms);
      this.deleteEmptyIds(this.personalForms);
    },
    validateEids() {
      const checkAndSetError = (item, fields) => {
          let hasValue = true;
          fields.forEach(field => {
              if (!item[field].value) {
                  item[field].error = "Missing property";
                  hasValue = false;
              }
          });
          return hasValue;
      };
  
      const hasFilledPersForms = this.personalForms.some(item =>
          checkAndSetError(item, ["firstName", "lastName", "email"])
        );
        
        const hasFilledMpaForms = this.mpaForms.some(item =>
          checkAndSetError(item, ["firstName", "lastName", "email"])
      );

      const hasFilledTeamForms = this.teamForms.length > 0 && this.teamForms.some(item =>
            checkAndSetError(item, ["teamName"])
        );
      return hasFilledPersForms || hasFilledMpaForms || hasFilledTeamForms;
    },
    // this method is similar to checkForEmpty inside setEmpty()
    // but it returns a boolean value instead of setting a flag
    // and it also iterates over the form array in reverse order
    // and diretly deletes the empty object from the array
    deleteEmptyIds(form) {
      let foundEmpty = false;
      for (let index = form.length - 1; index >= 0; index--) {
        const obj = form[index];
        const hasEmptyField = Object.entries(obj).some(([key, field]) => {
            return key !== 'hinEmail' && key !== 'name' && !field?.value;
          });
          if (hasEmptyField) {
            form.splice(index, 1);
            foundEmpty = true;
          }
      }
      return foundEmpty;
    },
    setEmpty() {
      const checkForEmpty = (arr) => {
        return arr.some(obj => Object.entries(obj).some(([key, field]) => {
          return key !== 'hinEmail' && key !== 'name' && !field?.value;
        }));
      };

      this.hasEmpty =
        checkForEmpty(this.personalForms) ||
        checkForEmpty(this.teamForms) ||
        checkForEmpty(this.mpaForms);
    },
    checkForDuplicateMail(emailToCheck) {
      let emailCount = 0;
      const forms = [...this.personalForms, ...this.mpaForms];

      forms.forEach(obj => {
        if (obj.email?.value === emailToCheck) {
          emailCount += 1;
        }
        obj.email.error = emailCount > 1 ? 'duplicateMailError' : '';
      });

      return emailCount <= 1;
    },
    setErrors() {
      // Define a function to check for errors in the eIDs
      // Check if any eID has an error in any of its fields
      // Check if any field in the eID has an error
      // Return true if the field has an error with a non-zero length
      const checkErrors = (eIDs) => {
        return eIDs.some((eID) => {
          return Object.values(eID).some((field) => {
            return field.error && field.error.length > 0;
          });
        });
      };

      this.hasErrors =
        checkErrors(this.personalForms) ||
        checkErrors(this.teamForms) ||
        checkErrors(this.mpaForms);
    },
    validateName(type, index, value, inputField) {
      const regex = /^(?!\s)(?!.*\s{2,})(?=.*[^\s])[\p{L}\p{M}\s’-]+$/u;

      if (value && /\S/.test(value)) {
        if (!regex.test(value)) {
          this[type][index][inputField].error = "special characters are not allowed";
        } else {
          this[type][index][inputField].error = "";
        }
      } else {
        this[type][index][inputField].error = "Invalid Input";
      }
    },
    validateTeamName(type, index, value, inputField) {
      if (value === "" || value === undefined) {
        this[type][index][inputField].error = "missing property";
      } else { 
        // this method is only checking for special characters
        const specialCharPattern = /[^a-zA-Z0-9\s]/;

        if (specialCharPattern.test(value)) {
          this[type][index][inputField].error = "special characters are not allowed";
        } else {
          this[type][index][inputField].error = "";
        }
      }
    },

    cleanDiacritics(email) {
      return removeDiacritics(email).replace(/\s+/g, '');
    },

    async validateEmail(type, index, value, activeStep) {
      // 2024-11-11: Turn check off by request.
      // if (this.checkForDuplicateMail(value) === false) {
      //   return;
      // }

      const responseData = await agwManagerService.fetchJson(
        "/productfinder/validate-email?email=" + value,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (responseData.errors) {
        if (activeStep === 3) {
          this[type][index].hinEmail.error = responseData.errors[0].message;
        } else {
          this[type][index].email.error = responseData.errors[0].message;
        }
      } else if (responseData.result) {
        if (activeStep === 3) {
          this[type][index].hinEmail.error = "";
        } else {
          this[type][index].email.error = "";
        }
      }
    },
    // addFormId: 1 = personal, 2 = mpa, 3 = team
    addNewForm(formName, addFormId) {
      try {
        if (
          this.teamForms.length +
            this.personalForms.length +
            this.mpaForms.length <
          this.maxForms
        ) {
          const persForm = {
            name: "",
            firstName: { error: "", value: "" },
            lastName: { error: "", value: "" },
            email: { error: "", value: "" },
            hinEmail: { error: "", value: "" },
          }
          const teamForm = {
              name: "",
              teamName: { error: "", value: "" },
              hinEmail: { error: "", value: "" },
          }
          switch (addFormId) {
            case 3:
              teamForm.name = formName;
              this.teamForms.push(teamForm);
              break;
            case 2:
              persForm.name = formName;
              this.mpaForms.push(persForm);
              break;
            case 1:
              persForm.name = formName;
              this.personalForms.push(persForm);
              break;
          }
        } else {
          console.log("Max forms reached");
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },

    removeForm(payload) {
      try {
        if(payload.formName === "personalForms"){
          this.personalForms.splice(payload.index, 1);
        } else if (payload.formName === "mpaForms") {
          this.mpaForms.splice(payload.index, 1);
        } else if (payload.formName === "teamForms") {
          this.teamForms.splice(payload.index, 1)
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },
  },
  persist: {
    storage: sessionStorage,
  }
});
