import { createI18n } from 'vue-i18n';

import de from './locales/de.js';
import fr from './locales/fr.js';
import it from './locales/it.js';

const i18n = createI18n({
  // In v11 "legacy"is deprecated and will be removed in v12
  // it is recommended to use composition API, as we do already instead of "legacy"
  // this is why we have to explicitly set it to false
  // for more Information see: https://vue-i18n.intlify.dev/guide/migration/breaking11
  legacy: false,
  locale: 'de',
  fallbackLocale: 'de',
  globalInjection: true,
  messages: {
    de,
    fr,
    it,
  },
});

export default i18n;

export const i18nGlobal = i18n.global;
