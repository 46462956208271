<script setup>
import Autocomplete  from "@/components/form/Autocomplete.vue";
import ErrorSmall    from "@/components/form/ErrorSmall.vue";
import LabelForInput from "@/components/form/LabelForInput.vue";
import DeleteInputIcon from "@/components/form/DeleteInputIcon.vue";

const selectedPhoneCountry = defineModel('selectedPhoneCountry');
const valuePhone = defineModel('valuePhone');

const props = defineProps({
  hint: "",
  label: "",
  language: "",
  optionsCountry: {},
  topic: "",
  namePhone: "",
  namePhoneCountry: "",
  required: "",
  errorPhoneCountry: "",
  errorPhone: "",
});

const emits = defineEmits(['validatePhoneCountry', 'validatePhone']);
</script>

<template>
  <div :class="['form-group', required && 'required']">
    <LabelForInput :name="namePhone" :hint :label />
    <div class="form-row">
      <div class="col-3 col-lx-3">
        <Autocomplete
          :name="namePhoneCountry"
          :error="errorPhoneCountry"
          :initialValue="'phoneCountryCodes.' + selectedPhoneCountry"
          :language
          :suggestions="optionsCountry"
          @validate="
            selectedPhoneCountry = $event.target.value;
            $emit('validatePhoneCountry', $event, optionsCountry);
            $emit('validatePhone', { target: { name: namePhone, value: valuePhone } }, selectedPhoneCountry);"
        />
      </div>
      <div class="col-9 col-xl-9" style="max-width: 370px;">
      <div>
        <div class="input-container">
          <input
            autocomplete="off"
            type="text"
            :class="['form-control', errorPhone && 'error-border']"
            :id="namePhone"
            :name="namePhone"
              v-model="valuePhone"
            :required
            @blur="$emit('validatePhone', { target: { name: namePhone, value: valuePhone } })"
          />
          <DeleteInputIcon v-if="errorPhone" @clearInput="valuePhone = ''" />
        </div>
      </div>
        <ErrorSmall :name="namePhone" :error="errorPhone" :label="label"/>
      </div>
    </div>
  </div>
</template>
